/*
@File: Addax Template Styles

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

*** - Default CSS
*** - Prealoader CSS 
*** - Main Header CSS
*** - Main Banner CSS
*** - Video Banner CSS
*** - Creative Banner CSS
*** - Particles Banner CSS
*** - Welcome Area CSS
*** - About Area CSS
*** - Who We Are Area CSS
*** - Strategy Area CSS
*** - CTR Area CSS
*** - Skill Area CSS
*** - Fun Facts Area CSS
*** - Team Area CSS
*** - How We Work CSS
*** - Services Area CSS
*** - Why We Are Different CSS
*** - Work Area CSS
*** - Pricing Area CSS
*** - FAQ Area CSS
*** - Testimonials Area CSS
*** - Blog Area CSS
*** - Partner Area CSS
*** - Subscribe Area CSS
*** - Contact Area CSS
*** - Page Title CSS
*** - Blog Details Area CSS
*** - Footer Area CSS
*** - Go Top CSS
*/

/*
==============================
Default CSS
==============================
*/
$family: unquote("Poppins");
$font_family: "Poppins", sans-serif;
// $template_color: #ff2c57;
// $template_color: #2CA8FF;
$template_color: #0080c8;
$template_color-dark: #002a79;

$dark-color: #2c3e50;
// $dark-grey-color: #0f0f0f;
$dark-grey-color: #1a1a1a;
$footer-color: #0c0c0c;

@import url("https://fonts.googleapis.com/css?family=#{$family}:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i");

/*
 mixins
*/
@import "./mixins.scss";

body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  // overflow-y: scroll;
  overflow: hidden;
}

.wrap-content {
  height: 100%;
  width: 100%;
  // padding-bottom: 2.5rem;
  overflow: hidden;
}

// body {
//   // overflow: auto;
//   overflow-x: hidden;
//   min-height: 100%;
//   min-width: 100vw;
//   // overflow-y: scroll;
//   margin: 0;
//   padding: 0;
//   height: 100%;
// }

// body {
//   padding: 0;
//   margin: 0;
//   background: #111111;
//   font-family: $font_family;
//   height: 150%;
//   // overflow-y: scroll;
// }

// #root {
//   min-width: 100vw;
//   min-height: 100%;
//   height: 100%;
//   position: relative;
// //   overflow-x: hidden;
// }

// #wrap-content{
//   padding-bottom: 2.5rem;
// }

// html, body {
//     overflow: auto;
//     overflow-x: hidden;
//     min-height: 100%;
//     min-width: 100vw;
//     overflow-y: scroll;
//     margin: 0;
//     padding: 0;
//     height: 100%;
// }

// body {
//     padding: 0;
//     margin: 0;
//     background: #111111;
//     font-family: $font_family;
//     // height: 150%;
//     overflow-y: scroll;
// }

// #root {
//     min-width: 100vw;
//     min-height: 100%;
//     position: relative;
//     overflow-y: scroll;

// }

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1;
  color: #fff;
}
ul {
  padding-left: 0;
  margin-bottom: 0;
  li {
    list-style: none;
  }
}
:focus {
  outline: 0 !important;
}
p {
  margin-bottom: 0;
  font-weight: 400;
  line-height: 1.8;
  color: #ffffff;
}
a {
  color: #ffffff;
  text-decoration: none;
  transition: 0.4s;
  &:hover,
  &:focus {
    color: $template_color;
    text-decoration: none;
  }
}
img {
  max-width: 100%;
}
.ptb-80 {
  padding: {
    bottom: 80px;
    top: 80px;
  }
}
.d-table {
  width: 100%;
  height: 100%;
  &-cell {
    vertical-align: middle;
  }
}
.bg-222222 {
  background: #222222;
}
/*Btn Btn-Primary*/
.btn {
  padding: 9px 25px;
  font-size: 16px;
  border-radius: 30px;
  transition: 0.4s;
}
.btn-primary {
  color: #ffffff;
  background-color: $template_color;
  border-color: $template_color;
  &:hover,
  &:focus,
  &.disabled:hover,
  &.disabled:focus {
    color: $template_color;
    background-color: #ffffff;
    border-color: $template_color;
  }
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active {
    color: #ffffff;
    background-color: $template_color;
    border-color: $template_color;
  }
  &:not(:disabled):not(.disabled).active:focus,
  &:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
  }
  &.focus,
  &:focus {
    box-shadow: none;
  }
  &.disabled,
  &:disabled {
    color: #ffffff;
    background-color: $template_color;
    border-color: $template_color;
    opacity: 1;
  }
}
/* Section Title */
.section-title {
  margin-bottom: 60px;
  padding-left: 30px;
  position: relative;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    left: 10px;
    top: 0;
    background: $template_color;
    transition: 0.4s;
  }
  &::after {
    left: 0;
    height: 50%;
  }
  &:hover::before {
    height: 50%;
  }
  &:hover::after {
    height: 100%;
  }
  h2 {
    font: {
      size: 35px;
      weight: 200;
    }
    margin-bottom: 20px;
    span {
      color: $template_color;
      font-weight: 600;
    }
  }
  p {
    max-width: 635px;
    margin-top: -7px;
  }
  h4 {
    font: {
      size: 18px;
      weight: 300;
    }
    color: $template_color;
    margin-bottom: 17px;
    text-transform: capitalize;
  }
}
/* Form Control */
.form-control {
  height: 45px;
  padding: 10px;
  font-size: 15px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 0;
  &:focus {
    border-color: #dddddd;
    outline: 0;
    box-shadow: none;
  }
}
/* Owl Carousel Button */
.owl-theme {
  .owl-controls {
    line-height: 0;
  }
  .owl-nav {
    margin-top: 50px;
  }
  .owl-dots {
    .owl-dot {
      span {
        width: 15px !important;
        height: 15px !important;
        margin: 0 4px !important;
        background: transparent !important;
        border-radius: 50% !important;
        border: 2px solid #dddddd !important;
        transition: 0.4s !important;
      }
      &.active span {
        background: #869791 !important;
        border-radius: 5px !important;
        width: 35px !important;
        height: 10px !important;
        border-color: $template_color !important;
        background: $template_color !important;
      }
      &:hover span {
        background: $template_color !important;
        border-color: $template_color !important;
      }
    }
  }
  .owl-nav {
    [class*="owl-"] {
      position: absolute;
      left: 40px;
      top: 50%;
      margin-top: -20px;
      background: rgba(255, 255, 255, 0.33);
      width: 60px;
      text-align: center;
      height: 60px;
      line-height: 54px;
      border-radius: 50%;
      font-size: 30px;
      color: #ffffff;
      transition: 0.4s;
      &:hover {
        background: $template_color;
        color: #ffffff;
      }
    }
    .owl-next {
      left: auto;
      right: 40px;
    }
  }
}

/*
==============================
Preloader CSS
==============================
*/
.preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #ffffff;
  z-index: 99999;

  .folding-cube {
    left: 0;
    width: 60px;
    height: 60px;
    position: absolute;
    transform: rotateZ(45deg);
    top: 50%;
    right: 0;
    margin: -60px auto 0;

    .cube {
      float: left;
      width: 50%;
      height: 50%;
      position: relative;
      transform: scale(1.1);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $template_color;
        animation: sk-foldCubeAngle 2.4s infinite linear both;
        transform-origin: 100% 100%;
      }
    }
    .cube2 {
      transform: scale(1.1) rotateZ(90deg);

      &::before {
        animation-delay: 0.3s;
      }
    }
    .cube3 {
      transform: scale(1.1) rotateZ(180deg);

      &::before {
        animation-delay: 0.6s;
      }
    }
    .cube4 {
      transform: scale(1.1) rotateZ(270deg);

      &::before {
        animation-delay: 0.9s;
      }
    }
  }
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

// box-shadow: inset 0 1px 2px -1px $color-dark, 0 1px 2px -1px $color-dark;
/*
==============================
Main Header CSS
==============================
*/
// .sidebar {
//     // z-index: 10000;
//     padding-top: 55px;
//     // position: fixed;
//     .drawer-item {
//         position: fixed;
//         .drawer-link {
//             position: fixed;
//         }
//     }
// }
.sidebar-content {
  // padding-top: 10px;
  // padding-left: 10px;
  // padding-right: 10px;
  // margin-left: 10px;

  margin: 20px;
  margin-top: 50px;
  .border-bottom {
    // margin-top: 5px;
    margin-right: -20px;
    margin-left: -20px;
    // border-top: 1px solid $dark-color;
    height: 4px;
    width: 150px;
  }
  .drawer-item {
    margin-top: 10px;
    .drawer-link {
      color: white;
      font-family: Arial, Helvetica, sans-serif;
      border-bottom: 1px solid $dark-color;
    }
  }
  .mobile-logo-wrapper {
    width: 150px !important;
    margin-right: -20px;
    margin-left: -20px;
    background-color: white;
    padding-bottom: 7px;
  }
  .sidebar-navbar-logo {
    margin-left: 3%;
    height: 40px;
    width: 75px;
  }
  .toggler-navbar-upper {
    overflow: hidden;
    margin-top: -50px;
    margin-right: -20px;
    margin-left: -20px;
    top: 0;
    height: 5px;
    background-color: $dark-color;
    padding-bottom: 8px;
  }
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: transparent !important;
  padding-top: 20px;
  padding-bottom: 20px;
  .navbar-logo {
    margin-top: -25px;
    margin-bottom: -20px;
    height: 50px;
  }
  .nav-link {
    display: unset;
  }
  .dropdown {
    margin-left: -15px;
    // padding-right: 5px !important;
  }
  padding-bottom: 50px;
  .toggler-wrapper {
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 55px;
    // background-color: $dark-grey-color;
    background-color: white;
    .toggler-navbar-upper {
      height: 5px;
      background-color: $dark-color;
      padding-bottom: 8px;
    }
    .toggler-navbar-logo {
      // margin-left: 5px;
      margin-left: 3%;
      margin-top: 2px;
      height: 40px;
      width: 75px;
    }
    // border-bottom-color: $dark-color !important;
    // border-bottom-width: 10px !important;
  }
  .navbar-toggler {
    position: fixed;
    top: 0;
    right: 0;
    margin-top: 10px;
    margin-right: 10px;
    // margin-top: 10px;
    border-color: $template_color !important;

    // 0080c8 == 0 128 200
    // background-color: $dark-grey-color;
    background-color: white;

    .navbar-toggler-icon {
      background-color: dark-color;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 128, 200, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
    }
  }
  // animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.header-sticky.is-sticky {
  position: fixed;
  top: 20px;
  left: 0;
  width: 100%;
  z-index: 999;
  // box-shadow: 0 8px 6px -6px rgba(0,0,0,.4);
  box-shadow: inset 0 1px 2px -1px $dark-color, 0 1px 2px -1px $dark-color;
  // animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffffff !important;

  // background-image: linear-gradient(to right, white 40% , $dark-color 45%) !important;
  .navbar-upper {
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    z-index: 999;
    animation: none;
    height: 20px;
    // background: $template_color !important;
    background: $dark-color !important;
  }
}
.navbar-light {
  pointer-events: none;
  .container {
    pointer-events: all;
  }
  .dropdown {
    padding-right: 5px;
  }
  .navbar-brand {
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;

    span {
      color: $template_color;
      font: {
        size: 35px;
        weight: 700;
      }
      text-transform: lowercase;
    }
    &:focus,
    &:hover {
      color: #ffffff;
    }
  }
  .navbar-nav {
    .nav-item {
      padding-right: 15px;
      padding-left: 15px;

      &:last-child {
        padding-right: 0;
      }

      .nav-link {
        color: #ffffff;
        padding-right: 0;
        padding-left: 0;
        &.active,
        &.show,
        &:hover,
        &:focus {
          color: $template_color;
        }
      }
    }

    .active,
    &.show {
      .nav-link {
        display: unset;
        color: $template_color;
      }
    }
  }
  &.header-sticky.is-sticky {
    .navbar-brand {
      color: #000000;
    }
    .navbar-nav {
      .nav-link {
        display: unset;
        color: #000000;
        padding-right: 0;
        padding-left: 0;
        &.active,
        &.show,
        &:hover,
        &:focus {
          color: $template_color;
        }
      }
      .active,
      .show {
        .nav-link {
          display: unset;
          color: $template_color;
        }
      }
    }
  }
}

/*
==============================
Main Banner CSS
==============================
*/
.main-banner {
  // height: 750px;
  // height: 10vh;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  background-image: $dark-grey-color;
  .image-wrapper {
    background: $dark-color;
    z-index: 100;
  }

  // top: 0;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 120vh;
    background: #ffffff;
    background: black;
    opacity: 0.25;
    // opacity: 0.26;
  }

  .main-banner-background-image {
    width: 100%;
    height: 120vh;
    @media only screen and (max-width: 600px) {
      height: 20%;
    }
    background-image: $dark-grey-color;
  }
  $shadow-width: 1px;
  $shadow-color: #beafbe;
  h1 {
    left: 0;
    color: #1a171b;
    text-transform: uppercase;
    font-family: Arial, Helvetica, sans-serif;
    transform: scaleY(0.75);
    transform-origin: 0 0;
    -webkit-filter: drop-shadow($shadow-width $shadow-width $shadow-width $shadow-color);
    filter: drop-shadow($shadow-width $shadow-width $shadow-width $shadow-color);
  }

  @media only screen and (max-width: 600px) {
    h1 {
      font-size: 1.5em;
      margin-top: 10px;
    }
  }

  .centered {
    position: absolute;
    .tbu-white-logo {
      -webkit-filter: drop-shadow($shadow-width $shadow-width $shadow-width $shadow-color);
      filter: drop-shadow($shadow-width $shadow-width $shadow-width $shadow-color);
      // -webkit-box-shadow: 0px 0px 3px 5px #f2e1f2;
      // -moz-box-shadow: 0px 0px 3px 5px #f2e1f2;
      // box-shadow: 0px 0px 3px 5px #f2e1f2;
    }

    @media only screen and (min-width: 600px) {
      transform: translate3d(40%, 140%, 0);
      width: 50%;
      margin-left: 18%;
      .tbu-white-logo {
        left: 0;
        width: 15vw;
        margin-bottom: 20px;
      }
    }

    @media only screen and (max-width: 600px) {
      margin-left: 10%;
      text-align: center;
      top: 30%;
      // left: 40%;
      // transform: translate(-50%, -50%);
      .tbu-white-logo {
        width: 45vw;
      }
    }
  }
}

/*
==============================
Projects Banner CSS
==============================
*/
.projects-banner {
  height: 400px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.77;
  }
  // canvas {
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  // }
  .projects-banner-background-image {
    width: 100%;
    // height: 50%;
    // z-index: -1;
  }

  .centered {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .tbu-white-logo {
      margin-bottom: 20px;
    }
  }
}

// TODO
// .item-bg-one { 
// 	background-image: url('../images/main-bg-1.jpg');
// }
// .item-bg-two { 
//   background-image: url("../images/main-bg-2.jpg");
// }
// .item-bg-three {
//   background-image: url("../images/main-bg-3.jpg");
// }
.main-banner-text {
  text-align: center;
  h4 {
    color: #ffffff;
    font: {
      weight: 200;
      size: 24px;
    }
    letter-spacing: 1px;
  }
  h1 {
    color: #ffffff;
    font: {
      size: 55px;
      weight: 200;
    }
    margin: {
      top: 25px;
      bottom: 30px;
    }
    letter-spacing: 2px;
    span {
      color: $template_color;
      font-weight: 600;
    }
  }
  p {
    color: #ffffff;
    max-width: 730px;
    margin: auto;
    margin-bottom: 30px;
  }
  .btn {
    &:hover,
    &:focus {
      border-color: #ffffff;
    }
  }
  .view-work {
    background: transparent;
    border-color: #fff;
    margin-left: 10px;

    &:hover,
    &:focus {
      border-color: $template_color;
      background: $template_color;
      color: #ffffff;
    }
  }
}
.home-slides {
  .owl-controls {
    margin-top: 0;
  }
}

/*
==============================
News CSS
==============================
*/

.section-news {
  background: $dark-grey-color;
  padding-top: 50px;
  padding-right: 10px;
  @media only screen and (max-width: 600px) {
    padding-right: 0;
  }
  .section-title {
    margin-bottom: 15px;
    p {
      max-width: 100%;
    }
  }

  .carousel-container {
    // padding
    width: 100%;
  }

  .carousel {
    margin-top: 15px;
    // margin-left: -15px;
  }

  .carousel-row {
    padding-top: 35px;
  }

  @media only screen and (min-width: 600px) {
    img {
      width: 100%;
      height: 65vh;
      // height: 30vw;
      // height: 40vw;
    }
  }

  // @media only screen and (max-width: 600px) {
  //   img {
  //     width: 90%;
  //     height: 30vh;
  //     // height: 30vw;
  //     // height: 40vw;
  //   }
  // }

  .carousel-item > img {
    object-fit: fill;
  }

  // .carousel-item:hover {
  //   cursor: pointer;
  // }

  @media only screen and (min-width: 600px) {
    .carousel-indicators li {
      height: 20px;
      width: 20px;
      margin-left: 8px;
      margin-right: 8px;
      background-color: #fff;
      border-bottom: 5px;
      margin-top: 15px;
    }

    .carousel-indicators .active {
      margin-top: 10px;
      height: 30px;
      width: 30px;
    }

    a.carousel-control-next {
      font-size: 3em;
      padding-left: 30px;
    }

    a.carousel-control-prev {
      font-size: 3em;
      padding-right: 30px;
    }
  }

  .carousel-caption {
    font-weight: bold;
    font-size: 2em;
    background-color: black;
    opacity: 0.7;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 40px;
    width: 100%;
    left: 0;
  }
}

.section-services-projects {
  background: $dark-grey-color;
  padding-top: 120px;
  padding-bottom: 50px;
  padding-right: 0px;
  white-space: pre-wrap;

  .section-title {
    p {
      max-width: 100%;
    }
    margin-bottom: 25px;
  }

  .container {
    @media only screen and (max-width: 600px) {
      margin-left: 5px;
    }
    .carousel-container {
      margin-left: 10px;
    }
    .card-deck {
      margin-bottom: 40px;
    }
    .card {
      white-space: pre-wrap;

      background-color: transparent;
      overflow: hidden;
      box-shadow: none;
      @media only screen and (max-width: 600px) {
        margin: 15px 15px 25px 0px;
      }
    }
    .card-title {
      font-weight: 600;
      text-transform: capitalize;
    }

    .card-body {
      background-color: $dark-color;
    }

    .zoom-wrapper {
      height: 300x;

      overflow: hidden;
    }

    .card-img:hover {
      transition: transform 0.5s ease;
      transform: scale(1.25);
    }

    .card-img {
      cursor: pointer;
      object-fit: cover;
      height: 240px;
      // min-height: 15vw;
      transition: transform 0.25s ease;
    }
  }
  .carousel-indicators {
    // margin-top: 10px;
    bottom: -8px;
  }
  .carousel-indicators li {
    background-color: #fff;
    // border-bottom: 5px;
    // margin-top: 25px;
  }
}

/*
==============================
Video Banner CSS
==============================
*/
.video-background {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  min-height: 100%;
  min-width: 100%;
  z-index: -100;
}

/*
==============================
Creative Banner CSS
==============================
*/
.creative-banner-one {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-width: 10rem 100vw 0 0;
  border-color: transparent #111111 #111111 transparent;
  border-style: solid;
}
.creative-banner-two {
  position: relative;
  top: 100%;
  width: 100%;
  height: 0;
  &::before {
    content: "";
    border-right: 50vw solid #111111;
    border-top: 160px solid transparent;
    bottom: 0;
    right: 0;
    position: absolute;
    height: 0;
    width: 0;
  }
  &::after {
    content: "";
    position: absolute;
    border-left: 50vw solid #111111;
    border-top: 160px solid transparent;
    bottom: 0;
    height: 0;
    left: 0;
    width: 0;
  }
}
// TODO
.creative-banner-three {
  position: relative;
  top: 100%;
  width: 100%;
  height: 0;
  &::before {
    content: "";
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
    // background-image: url("../images/shape.png");
    height: 150px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

/*
==============================
Particles Banner CSS
==============================
*/
#particles-js {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
#particles-js-two {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

/*
==============================
Welcome Area CSS
==============================
*/
.single-box {
  text-align: center;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  padding: 30px 20px;
  position: relative;
  overflow: hidden;
  transition: 0.4s;
  &:hover {
    box-shadow: unset;
    transform: translateX(5px);
  }
  .icon {
    color: $template_color;
    font-size: 45px;
  }
  h3 {
    margin: {
      top: 25px;
      bottom: 20px;
    }
    font: {
      weight: 600;
      size: 20px;
    }
  }
  a {
    position: absolute;
    right: -20px;
    bottom: -20px;
    width: 45px;
    height: 40px;
    line-height: 44px;
    text-align: center;
    box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
    border-radius: 50% 0 0 0;
    opacity: 0;
    visibility: hidden;
  }
  &:hover a {
    background: $template_color;
    color: #ffffff;
    opacity: 1;
    visibility: visible;
    right: 0;
    bottom: 0;
  }
}

/*
==============================
About Area CSS TODO
==============================
*/
.about-area-floating {
  margin-top: -20%;
  // background-image: url('../images/patt.png');
  background-position: center center;
  background-color: $dark-grey-color;
  // z-index: 10000;
  position: relative;
  width: 45%;
  left: 40%;
  box-shadow: 2px 2px 4px -2px #2c3e50;
}

.about-area-flat {
  // margin-top: -20%;
  // background-image: url('../images/patt.png');
  background-position: center center;
  background-color: $dark-grey-color;
  // z-index: 10000;
  position: relative;
  padding-top: 40px;
  // margin-right: 10px;
  padding-right: 5px;

  .section-title {
    // margin-top: -5px;
    margin-bottom: 30px;
  }
  .subtitle {
    margin-top: 30px;
  }

  .container {
    .paragraphs {
      text-align: left;
      margin-bottom: 10px;
      white-space: pre-wrap;
      color: #fff;
      font-weight: 400;
      line-height: 1.8;
    }
  }
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.2);
    opacity: 0;
  }
}
.about-addax {
  margin-top: 12px;
  .section-title {
    margin-bottom: 20px;
    padding-left: 0;
    &::before,
    &::after {
      display: none;
    }
    h2 {
      font-size: 25px;
    }
  }
  ul {
    margin-bottom: 30px;
    li {
      margin-bottom: 10px;
      font-weight: 400;
      color: #dddddd;
      i {
        color: $template_color;
        margin-right: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .pull-left {
    margin-right: 45px;
  }
}

/*
==============================
Who We Are Area CSS
==============================
*/
.who-we-are {
  padding-bottom: 50px;
  background: $dark-grey-color;
}
.single-who-we-are {
  border-radius: 5px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 20px 40px 0 rgba(28, 30, 35, 0.2);
  background: $dark-color;
  padding: 50px 30px;
  transition: all 0.5s ease-in-out 0s;
  &:hover {
    background: $template_color;
    color: #ffffff;
    transform: translateX(5px);
  }
  i {
    font-size: 50px;
    color: $template_color;
    transition: 0.4s;
  }
  &:hover i {
    color: #ffffff;
  }
  h4 {
    margin: {
      bottom: 20px;
      top: 25px;
    }
    font: {
      size: 22px;
      weight: 500;
    }
  }
  p {
    transition: 0.4s;
  }
  &:hover p {
    color: #ffffff;
  }
  span {
    bottom: 0;
    color: rgba(0, 0, 0, 0.03);
    font-size: 200px;
    line-height: 180px;
    position: absolute;
    right: 0;
    transition: 0.4s;
  }
  &:hover span {
    color: rgba(255, 255, 255, 0.2);
  }
}

/*
==============================
Team Area CSS
==============================
*/
.strategy-area {
  position: relative;
  // display: table;
  // display: inline-flex;
  // overflow: overlay;
  // height: 50vh;
  // display: inline;

  @media only screen and (max-width: 600px) {
    padding-bottom: 5vh;
    padding-left: 15px;
  }

  .card-container {
    // margin-right: 10px;
    margin-left: 5px;
    padding-left: 0px;
  }

  .team-description {
    position: relative;
    height: 100%;

    padding-top: 40px;

    display: table-cell;
    @media only screen and (max-width: 600px) {
      padding-bottom: 30px;
      margin-right: 8px;
    }
    @media only screen and (min-width: 600px) {
      min-height: 500px;
    }

    ul {
      // color: rgb(187, 186, 186);
      color: #fff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 2.5em;
      @media only screen and (max-width: 600px) {
        line-height: 1.5em;
        display: inline-grid;
      }

      li {
        display: inline-block;

        margin-bottom: 10px;
        @media only screen and (min-width: 600px) {
          min-width: 51%;
          margin-left: 3.5%;
        }
      }
    }
    .section-title {
      margin-bottom: 20px;
    }
  }

  .carousel {
    display: table-cell;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    @media only screen and (min-width: 600px) {
      min-height: 500px;
    }
    // height: 50vh;
    height: 100%;

    background: $dark-grey-color;
    @media only screen and (max-width: 600px) {
      height: 40vh;
      margin-top: 30px;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    font-size: 2em;
    height: 30px;
    width: 30px;
    top: 50%;
  }

  .carousel-control-next {
    right: 10px;
  }

  .carousel-control-prev {
    left: 10px;
  }
  .carousel-slide,
  .carousel-inner {
    height: 100%;
  }
  .carousel-inner .carousel-item {
    width: 100%;
    // height: 50vh;
    height: 100%;
    @media only screen and (max-width: 600px) {
      height: 50vh;
    }

    background: $dark-grey-color;
    .image {
      // height: 100%;
      display: inline;
      img {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
      }
      .box {
        position: absolute;
        bottom: 40px;
        width: 80%;
        left: 10%;
        text-align: center;
        display: inline-block;

        z-index: 2;
        h5 {
          color: transparent;
        }
      }
    }

    .image:hover {
      .box {
        animation: 300ms ease-in-out 0s normal none 1 running fadeInUp;

        border: 25px solid black;
        background: black;
        h5 {
          color: white;
        }
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.77;
    @media only screen and (max-width: 600px) {
      a {
        color: white !important;
      }
    }
  }
}
.about-strategy {
  padding-left: 25px;

  .section-title {
    margin-bottom: 20px;
    padding-left: 0;
    &::before,
    &::after {
      display: none;
    }
  }
  ul {
    margin-bottom: 30px;

    li {
      margin-bottom: 10px;
      font-weight: 400;
      color: #dddddd;
      &:last-child {
        margin-bottom: 0;
      }
      i {
        color: $template_color;
        margin-right: 5px;
      }
    }
  }
}

/*
==============================
CTR Area CSS TODO
==============================
*/
.ctr-area {
  text-align: center;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.88;
  }
  .section-title {
    padding-left: 0;

    &::after,
    &::before {
      display: none;
    }
    h2 {
      color: #ffffff;
      font-weight: 500;
      margin: {
        top: 30px;
        bottom: 30px;
      }
    }
    p {
      margin: {
        left: auto;
        right: auto;
        bottom: 25px;
      }
    }
  }
  .view-work {
    background: transparent;
    border-color: #ffffff;
    margin-left: 10px;
    &:hover,
    &:focus {
      border-color: $template_color;
      background: $template_color;
      color: #ffffff;
    }
  }
  .btn:hover,
  .btn:focus {
    border-color: #ffffff;
  }
}

/*
==============================
Skill Area CSS
==============================
*/
.about-skill {
  .section-title {
    padding-left: 0;

    &::before,
    &::after {
      display: none;
    }
  }
  .progress-title {
    font-size: 17px;
    margin-bottom: 13px;
  }
  .progress {
    height: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: none;
    margin-bottom: 30px;
    overflow: visible;

    .progress-bar {
      box-shadow: none;
      position: relative;
      animation: animate-positive 2s;
      background: $template_color;
      border-radius: 5px;
    }
    .progress-value {
      font: {
        size: 15px;
        weight: 300;
      }
      color: #dddddd;
      position: absolute;
      top: -29px;
      right: 0;
    }
  }
}
@-webkit-keyframes animate-positive {
  0% {
    width: 0;
  }
}
@keyframes animate-positive {
  0% {
    width: 0;
  }
}
.skill-video {
  position: relative;

  .video-btn {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: -26px;
    a {
      color: #ffffff;
      display: inline-block;
      width: 70px;
      height: 70px;
      line-height: 72px;
      background: $template_color;
      position: relative;
      font-size: 30px;
      border-radius: 50%;

      &::before,
      &::after {
        content: "";
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 88px;
        height: 88px;
        border-radius: 50%;
        -webkit-transition: all 200ms;
        transition: all 200ms;
        animation: pulse-border 1500ms ease-out infinite;
        border: 10px solid #ffffff;
      }
      &::after {
        width: 70px;
        height: 70px;
        transition: all 200ms;
      }
    }
  }
}
@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.3);
    opacity: 0;
  }
}

/*
==============================
Fun Facts Area CSS
==============================
*/
.funFacts-area {
  // background-image: url('../images/fact_bg.jpg');
  // background: $dark-grey-color;
  // background: #212529;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  .bg-image {
    margin-top: 0;
    top: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -100;
    overflow: hidden;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.77;
  }
}
.funFact {
  position: relative;
  padding-left: 65px;

  i {
    font-size: 50px;
    color: $template_color;
    position: absolute;
    left: 0;
    top: 2px;
  }
  .fa.fa-users {
    left: -10px;
  }
  h2 {
    color: #ffffff;
    margin-bottom: 5px;
    font: {
      size: 45px;
      weight: 600;
    }
  }
  p {
    font-size: 18px;
  }
}

/*
==============================
Team Area CSS
==============================
*/
.our-team {
  text-align: center;
  position: relative;

  .team-content {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 92%;
    background: #111111;
    margin-left: 15px;
    padding-top: 20px;
    z-index: 1;
    transition: 0.4s;

    .title {
      font: {
        size: 20px;
        weight: 600;
      }
      color: #ffffff;
      margin-bottom: 6px;
      transition: 0.4s;
    }
    .post {
      color: #dddddd;
      transition: 0.4s;
    }
  }
  &:hover .team-content {
    .title {
      color: #ffffff;
    }
    .post {
      color: #ffffff;
    }
  }
  &:hover .team-content {
    bottom: 34%;
    background: transparent;
  }
  .social {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0.3) 80%);
    transition: 0.4s;
    opacity: 0;
    visibility: hidden;
    ul {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      margin-top: -50px;
      transform: translateY(-35px);
      transition: 0.4s;
      li {
        display: inline-block;
        margin: 0 3px;
        a {
          width: 35px;
          height: 35px;
          line-height: 32px;
          color: #ffffff;
          border: 1px solid #ffffff;
          border-radius: 50%;
          &:hover {
            border-color: $template_color;
            background: $template_color;
          }
        }
      }
    }
  }
  &:hover {
    .social {
      opacity: 1;
      visibility: visible;
      ul {
        transform: translateY(0);
      }
    }
  }
}

/*
==============================
How We Work CSS
==============================
*/
.single-how-work {
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background: $dark-grey-color;
  padding: 35px;
  border-radius: 5px;
  transition: all 0.5s ease-in-out 0s;
  &:hover {
    transform: translateX(5px);
  }
  h3 {
    color: $template_color;
    font: {
      size: 45px;
      weight: 700;
    }
  }
  h4 {
    margin: {
      top: 25px;
      bottom: 18px;
    }
    font-size: 22px;
  }
}

/*
==============================
Services Area CSS
==============================
*/
.services-area {
  padding-bottom: 50px;

  .single-box {
    margin-bottom: 30px;
  }
}
.single-services {
  margin-bottom: 30px;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  text-align: center;
  position: relative;
  transition: 0.4s;

  &:hover {
    box-shadow: unset;
    transform: translateX(5px);
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50px;
    border-left: 2px solid $template_color;
    height: 50px;
    border-bottom: 2px solid $template_color;
    opacity: 0;
    transition: 0.4s;
  }
  &:hover::before {
    opacity: 1;
    width: 100%;
    height: 50%;
  }
  .services-img {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #000000;
      opacity: 0.66;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      margin-top: -30px;
    }
  }
  i {
    width: 70px;
    height: 70px;
    line-height: 71px;
    border: 1px solid #ffffff;
    font-size: 30px;
    color: #ffffff;
    border-radius: 50%;
    transition: 0.4s;
  }
  &:hover i {
    background: #ffffff;
    color: $template_color;
  }
  .services-content {
    padding: 30px 25px;
    position: relative;
    h3 {
      font: {
        size: 22px;
        weight: 500;
      }
      margin-bottom: 18px;
    }
  }
}

/*
==============================
Why We Are Different CSS
==============================
*/
.tabset {
  input[type="radio"] {
    display: none;
  }
  .tab-panel {
    display: none;
  }
  input {
    &:first-child:checked ~ .tab-panels {
      .tab-panel {
        &:first-child {
          display: block;
        }
      }
    }
    &:nth-child(3):checked ~ .tab-panels {
      .tab-panel {
        &:nth-child(2) {
          display: block;
        }
      }
    }
    &:nth-child(5):checked ~ .tab-panels {
      .tab-panel {
        &:nth-child(3) {
          display: block;
        }
      }
    }
    &:nth-child(7):checked ~ .tab-panels {
      .tab-panel {
        &:nth-child(4) {
          display: block;
        }
      }
    }
    &:nth-child(9):checked ~ .tab-panels {
      .tab-panel {
        &:nth-child(5) {
          display: block;
        }
      }
    }
    &:nth-child(11):checked ~ .tab-panels {
      .tab-panel {
        &:nth-child(6) {
          display: block;
        }
      }
    }
  }
  label {
    position: relative;
    display: inline-block;
    padding: 15px 32px;
    margin: {
      right: 6px;
      bottom: 0;
    }
    color: #ffffff;
    cursor: pointer;
    background: transparent;
    transition: background ease 0.2s;
    border: 1px solid #dddddd;
    border-radius: 5px;
    font-size: 18px;
  }
  input:checked + label {
    border-color: $template_color;
    background: $template_color;
    color: #ffffff;
  }
  label {
    &.checked {
      border-color: $template_color;
      background: $template_color;
      color: #ffffff;
    }
  }
}
.tab-panel {
  margin-top: 35px;

  .why-we-different-text {
    h4 {
      font: {
        size: 35px;
        weight: 200;
      }
      margin-bottom: 20px;
    }
    p {
      max-width: 635px;
      margin-top: -7px;
    }
    ul {
      margin: {
        top: 25px;
        bottom: 30px;
      }

      li {
        margin-bottom: 10px;
        font-weight: 400;
        color: #dddddd;

        &:last-child {
          margin-bottom: 0;
        }
        i {
          color: $template_color;
          margin-right: 5px;
        }
      }
    }
  }
}
/*
==============================
Work Area CSS
==============================
*/

.work-area {
  background-color: $dark-grey-color;
}

.shorting-menu {
  display: inline-block;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  padding: 18px 20px;
  border-radius: 50px;
  background: $dark-grey-color;

  .filter {
    border: none;
    background: #111111;
    padding: 9px 24px;
    text-transform: capitalize;
    font-size: 16px;
    cursor: pointer;
    margin: 0 3px;
    transition: 0.4s;
    border-radius: 30px;
    color: #ffffff;
    &.programs-filter-btn-active,
    &:hover {
      background: $template_color !important;
      color: #ffffff;
    }
  }
}
.shorting {
  margin-top: 40px;
  max-width: 2000px;
  // .mix {
  //     display: none;
  // }
}
.projects-description {
  position: relative;
  color: white;
  white-space: pre;
  padding-bottom: 10px;

  .project-description-show-less,
  .project-description-show-more {
    margin-top: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}
.single-work {
  overflow: hidden;
  position: relative;
  padding: 5px;
  width: 640px;
  height: 360px;

  &::hover {
    overflow: hidden;
  }

  .project-image {
    width: 640px;
    height: 360px;
    transition: transform 0.75s ease;
  }
  .project-image:hover {
    transition: transform 0.75s ease;
    transform: scale(1.25);
    cursor: pointer;
  }
  &::before {
    content: "";
    position: absolute;
    top: 15px;
    width: 95%;
    height: 93%;
    background: #000000;
    left: 14px;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
  }
  // &:hover::before {
  //     top: 21px;
  //     width: 93%;
  //     height: 91%;
  //     left: 21px;
  //     opacity: .66;
  //     visibility: visible;
  // }
  .work-content {
    position: absolute;
    bottom: 20px;
    width: 86%;
    background: $dark-grey-color;
    left: 40px;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    h4 {
      font-size: 22px;
      margin-bottom: 10px;
    }
    ul {
      li {
        display: inline-block;
        font-size: 14px;
        color: #dddddd;
      }
    }
  }
  &:hover .work-content {
    bottom: 35px;
    opacity: 1;
    visibility: visible;
  }
  .popup-btn {
    position: absolute;
    top: 20px;
    right: 40px;
    width: 45px;
    text-align: center;
    height: 45px;
    line-height: 46px;
    border-radius: 50%;
    color: #ffffff;
    font-size: 22px;
    opacity: 0;
    visibility: hidden;
    border: 1px solid #ffffff;
    transition: 0.4s;
    &:hover {
      background: $template_color;
      border-color: $template_color;
    }
  }
  // &:hover .popup-btn {
  //     top: 36px;
  //     opacity: 1;
  //     visibility: visible;
  // }
}
.single-work-x {
  $work-item-width: 640px;
  $work-item-height: 360px;
  overflow: hidden;
  position: relative;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  // width: 640px;
  // height: 360px;
  width: $work-item-width;
  height: $work-item-height;

  &:hover {
    overflow: hidden;
    width: 640px;
    height: 360px;
    width: $work-item-width;
    height: $work-item-height;
  }
  p {
    white-space: pre-line;
  }
  img {
    // width: 640px;
    height: 360px;
    width: $work-item-width;
    height: $work-item-height;
    transition: transform 0.75s ease;
    // overflow: hidden;
    // display: inline;
  }

  .project-image:hover img {
    transition: transform 0.75s ease;
    transform: scale(1.25);
    cursor: pointer;
    overflow: hidden;
    // width: 640px;
    // height: 360px;
    width: $work-item-width;
    height: $work-item-height;
  }

  .project-image {
    transition: transform 0.75s ease;
    overflow: hidden;
    display: inline-block;
  }

  &::before {
    content: "";
    position: absolute;
    top: 15px;
    width: 95%;
    height: 93%;
    background: #000000;
    left: 14px;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
  }
  // &:hover::before {
  //     top: 21px;
  //     width: 93%;
  //     height: 91%;
  //     left: 21px;
  //     opacity: .66;
  //     visibility: visible;
  // }
  .work-content {
    position: absolute;
    bottom: 20px;
    width: 90%;
    background: $dark-grey-color;
    // left: 40px;
    left: 5%;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s;
    p {
      left: 0;
      margin-left: 0;
      padding-left: 0;
    }
    h4 {
      font-size: 22px;
      margin-bottom: 10px;
    }
    ul {
      li {
        display: inline-block;
        font-size: 14px;
        color: #dddddd;
      }
    }
  }
  &:hover .work-content {
    bottom: 35px;
    opacity: 1;
    visibility: visible;
  }
  // .popup-btn {
  //   position: absolute;
  //   top: 20px;
  //   right: 40px;
  //   width: 45px;
  //   text-align: center;
  //   height: 45px;
  //   line-height: 46px;
  //   border-radius: 50%;
  //   color: #ffffff;
  //   font-size: 22px;
  //   opacity: 0;
  //   visibility: hidden;
  //   border: 1px solid #ffffff;
  //   transition: 0.4s;
  //   &:hover {
  //     background: $template_color;
  //     border-color: $template_color;
  //   }
  // }
  // &:hover .popup-btn {
  //     top: 36px;
  //     opacity: 1;
  //     visibility: visible;
  // }
}
/*
==============================
Pricing Area CSS TODO
==============================
*/
.pricingTable {
  padding: 30px 0;
  text-align: center;
  position: relative;
  // background-image: url("../images/patt.png");
  background-position: center center;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  transition: 0.4s;

  &:hover {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }
  &::after {
    content: "";
    width: 70px;
    height: 30px;
    background: $template_color;
    margin: 0 auto;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    clip-path: polygon(50% 100%, 0 0, 100% 0);
  }
  .title {
    padding: 20px 0;
    margin-bottom: 35px;
    background: $template_color;
    font-size: 25px;
    color: #ffffff;
    text-transform: uppercase;
  }
  .price-value {
    margin-bottom: 25px;
    color: $template_color;
    .amount {
      display: inline-block;
      font-size: 44px;
      font-weight: 500;
    }
    .month {
      display: block;
      font-size: 15px;
      line-height: 16px;
      text-transform: capitalize;
      color: #dddddd;
      margin-left: 50px;
    }
  }
  .currency {
    display: inline-block;
    font-size: 25px;
    vertical-align: top;
    margin-top: 6px;
  }
  .pricing-content {
    border: {
      top: 2px solid #eeeeee;
      bottom: 2px solid #eeeeee;
    }
    margin-bottom: 30px;
    padding: {
      top: 25px;
      bottom: 25px;
    }
    li {
      color: #dddddd;
      margin-top: 16px;
      &:first-child {
        margin-top: 0;
      }
      i {
        color: $template_color;
        margin-right: 4px;
      }
    }
  }
}

/*
==============================
FAQ Area CSS TODO
==============================
*/
.faq-area .image {
  // background-image: url("../images/faq-img.jpg");
  position: absolute;
  left: 15px;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.accordion {
  .accordion__item {
    box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    margin-bottom: 10px;
  }
  .accordion__item + .accordion__item {
    border-top: none;
  }
  .accordion__button {
    background-color: #111111;
    color: #ffffff;
    transition: 0.5s;

    &:hover {
      background-color: $template_color;
      color: #ffffff;
    }
  }
}
.card {
  background-color: transparent;
  border-radius: 10px !important;
  margin-bottom: 6px;
  border: none;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);

  .card-header {
    padding: 0;
    margin-bottom: 0;
    border: none;
    background: transparent;
    &:first-child {
      border-radius: 0;
    }
    a {
      display: block;
      &:hover {
        background: $template_color;
      }
      h5 {
        font-size: 17px;
        overflow: hidden;
        padding: 20px;
        color: #ffffff;
        line-height: 16px;
        background: $template_color;
      }
      &.collapsed h5 {
        color: #ffffff;
        background: transparent;
        transition: 0.4s;
        &:hover {
          color: #ffffff;
        }
      }
      i {
        float: right;
        font-size: 16px;
      }
      &.collapsed {
        .fa.fa-plus {
          &::before {
            content: "\f067";
          }
        }
      }
      .fa.fa-plus {
        &::before {
          content: "\f068";
        }
      }
    }
  }
  .card-body {
    line-height: 1.9;
    background: transparent;
    color: #dddddd;
  }
}

/*
==============================
Testimonials Area CSS TODO
==============================
*/
.testimonials-area {
  padding-bottom: 50px;
}
.single-feedback {
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  // background-image: url("../images/patt.png");
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  position: relative;
  transition: all 0.5s ease-in-out 0s;

  .client-info {
    overflow: hidden;
    margin-bottom: 30px;

    .client-pic {
      float: left;
      width: 30%;

      img {
        border-radius: 50%;
      }
    }
    .client-title {
      float: left;
      width: 70%;
      padding-left: 20px;
      margin-top: 17px;

      h4 {
        font-size: 19px;
        font-weight: 500;
        transition: 0.4s;
      }
      h5 {
        font-size: 15px;
        margin-top: 13px;
        color: #dddddd;
        transition: 0.4s;
      }
    }
  }
  p {
    transition: 0.4s;
  }
  span {
    font-size: 175px;
    position: absolute;
    bottom: 0;
    right: 0;
    color: rgba(0, 0, 0, 0.03);
    transition: 0.4s;
    line-height: 0;
  }
  &:hover {
    transform: translateX(5px);
    background: $template_color;

    .client-title {
      h4,
      h5 {
        color: #ffffff;
      }
    }
    p {
      color: #ffffff;
    }
    span {
      color: rgba(255, 255, 255, 0.2);
    }
  }
}

/*
==============================
Blog Area CSS
==============================
*/
.single-blog-item {
  position: relative;
  padding: 30px;
  border: 1px solid #000000;
  overflow: hidden;
  margin-bottom: 30px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    transition: 0.4s;
  }
  span,
  p,
  h4 {
    position: relative;
    transition: 0.4s;
  }
  span {
    color: #dddddd;
  }
  h4 {
    margin: {
      top: 15px;
      bottom: 15px;
    }
    line-height: 1.5;
    font: {
      size: 20px;
      weight: 500;
    }

    a {
      &:hover {
        color: $template_color !important;
      }
    }
  }
  .link-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 45px;
    height: 40px;
    line-height: 44px;
    text-align: center;
    box-shadow: 0px 0px 25px 0px rgba(0, 27, 103, 0.3);
    border-radius: 50% 0 0 0;
  }
  &:hover {
    &::before {
      background: #000000;
      opacity: 0.66;
    }
    span,
    p,
    h4 a {
      color: #ffffff;
    }
    .link-btn {
      background: $template_color;
      color: #ffffff;
    }
  }
}

/*
==============================
Partner Area CSS
==============================
*/
.partner-area {
  text-align: center;

  .owl-carousel {
    .owl-item {
      img {
        width: 140px;
      }
    }
  }
}

/*
==============================
Subscribe Area CSS
==============================
*/
.subscribe-area {
  text-align: center;
  background-color: #111111;
}
.newsletter {
  max-width: 750px;
  margin: {
    left: auto;
    right: auto;
  }
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  background: $dark-grey-color;
  padding: 35px;
  border-radius: 5px;

  h4 {
    text-transform: capitalize;
    margin-bottom: 30px;
    font-size: 30px;
  }
  form {
    position: relative;
    .form-control {
      box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
      border: none;
      height: 75px;
      border-radius: 50px;
      padding-left: 20px;
      font-size: 17px;
    }
    .btn {
      position: absolute;
      right: 7px;
      top: 8px;
      height: 60px;
      width: 175px;
    }
    .validation-danger {
      margin-top: 20px;
      color: red;
    }
    .validation-success {
      margin-top: 20px;
    }
  }
}

/*
==============================
References Area CSS
==============================
*/
.section-references {
  padding-top: 80px;
  // padding-left: 140px;
  // padding-right: 150px;
  // padding-bottom: 60px;
  background: $dark-grey-color;
  width: 100%;
  max-width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  h5 {
    font-weight: bold;
  }
  .section-title {
    margin-right: 10px;
    p {
      max-width: 100%;
    }
    margin-bottom: 30px;
  }

  .headerText {
    color: $dark-grey-color;
    // background-color: black;
  }
  .card-deck {
    margin-bottom: 40px;
  }
  .reference-card {
    padding-left: 5px;
    padding-right: 20px;
    padding-bottom: 15px;
    //   margin-right: 100px;
  }
  .card {
    padding-top: 20px;
    background-color: #2f2f2f;
  }
  .card-img {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 70px;
    object-fit: contain;
  }
  .react-multiple-carousel__arrow {
    // margin-top: 100px;
    // margin-left: 150px;
    z-index: 1;
  }
  .card:hover {
    background-color: $dark-color;

    cursor: pointer;
  }

  .references-link-subsection {
    position: relative;
    margin-top: 50px;
    h4 {
      font-size: 1.2em;
    }
    .pdf-link {
      // line-height: 2;
      margin-left: 10px;
      text-decoration: underline;
      color: $template_color;
    }
    .pdf-link:hover {
      color: white;
      cursor: pointer;
    }
  }

  h5 {
    padding-bottom: 14px;
    padding-top: 14px;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ul {
    height: 400px;
    margin-top: 25px;
    color: white;
    overflow-y: scroll;
    .linkable:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  // }
}

/*
==============================
Project Detail Area CSS
==============================
*/

.section-halfscreen-carousel {
  position: fixed;
  // height: 1000px;
  // padding-bottom: 100px;
  // margin-bottom: 1000px;
  // z-index: -1;

  a.prev-link {
    font-size: 4em;
    position: fixed;
    right: 10px;
    font-size: 3.5em;
    z-index: 10;
    transition: opacity .15s ease;
    opacity: 0.5;
    color: white;
  }
  a.prev-link:hover {
    opacity: 0.8;
    cursor: pointer;
  }

  .carousel {
    position: fixed;
    top: 0;
    width: 100%;
    // z-index: -1;
    .carousel-item {
      width: 100%;

      @media only screen and (min-width: 1800px) {
        padding-left: 6cm;
        padding-right: 6cm;
      }
      background-color: $dark-grey-color;
      img {
        // width: 100vw;
        height: 100vh;
        // z-index: -1;
	display: block;
        margin: 0 auto;
      }
    }
  }

  .fa-chevron-right, 
  .fa-chevron-left {
    font-size: 3.3em;
  }

  a.carousel-control-next {
    font-size: 1.2em;
    padding-left: 30px;
  }

  a.carousel-control-prev {
    font-size: 1.2em;
    padding-right: 30px;
  }

  .carousel-indicators {
    // margin-bottom: 100px;
    // margin-left: 10%;
    opacity: 1;
    // left: 10%;
    right: 60%;
  }
  .to-details {
    position: fixed;
    // z-index: 100;
    top: 85%;
    left: 49%;
    align-items: center;

    h4 {
      text-align: left;
      padding-right: 10px;
      opacity: 0;
    }
    .fa-chevron-up {
      position: absolute;
      z-index: 100;
      color: white;
      opacity: 0.6;
      // top: 85%;
      font-size: 3em;
      // padding-right: 30px;
      // left: 49%;
    }
    .fa-chevron-down:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
  .to-details:hover {
    h4 {
      opacity: 1;
    }
  }
}

.section-project-detail-description {
  margin-top: 100vh;
  margin-left: 0;
  min-height: 300px;
  width: 100%;
  // margin-bottom: 50px;
  background: $dark-grey-color;
  position: relative;

  .source-link {
    position: absolute;
    right: 0px;
    margin-right: 12px;
    text-decoration: underline;
    color: $template_color;
  }
  .source-link:hover {
    color: white;
    cursor: pointer;
  }

  .fa-chevron-up,
  .fa-chevron-down {
    position: absolute;
    margin-left: 45%;
    @media only screen and (max-width: 600px) {
      margin-left: 25%;
    }
    color: white;
    cursor: pointer;
    opacity: 0.6;
    font-size: 3em;
    // padding-top: 10px;
    margin-top: -65px;
    padding-top: -10px;
    .a {
      font-family: Arial, Helvetica, sans-serif;
    }
  }
  .fa-chevron-up:hover,
  .fa-chevron-down:hover {
    opacity: 1;
  }

  .details {
    margin-top: 40px;
  }
  .container {
    .row {
      background: $dark-grey-color;
      align-content: flex-end;
      .col {
        // padding-top: 15px;
        background: $dark-grey-color;
        // height: 1000px;
        align-items: flex-end;
      }
      @media only screen and (max-width: 600px) {
        display: grid;
      }
    }
    max-width: 100%;
    width: 100%;
    background: $dark-grey-color;
    margin: 0;
    padding-top: 1px;
    padding-bottom: 20px;

    align-items: center;
    .section-title {
      margin-right: 20%;
      margin-left: 20%;
      background: $dark-grey-color;
      // height: 40px;

      h4 {
        text-transform: none;
        line-height: 1.1em;
        white-space: pre-line;
        color: white;
      }
    }
  }
}

/*
==============================
Contact Area CSS
==============================
*/
#map {
  // padding-top: 90px;
  width: 100%;
  height: 700px;
  @media only screen and (max-width: 600px) {
    height: 300px;
  }
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  right: 0;
}
.contact-area {
  height: 930px;
  @media only screen and (max-width: 600px) {
    height: 530px;
  }
  background: $dark-grey-color;
  padding-top: 140px;
  .section-title {
    margin-bottom: 0px;
    margin-left: 5%;
    h4 {
      color: white;
      text-transform: none;
    }
  }
  .mobile-contact-information {
    margin-bottom: 15px;
    b {
      color: white;
    }
  }
}

.contact-info {
  // width: 495px;
  width: 400px;
  margin-top: 230px;
  margin-left: 200px;
  box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
  // background: $dark-grey-color;
  background: white;

  .upper-border {
    .background-img {
      position: absolute;
      height: 65px;
      margin-left: 159px;
    }
    .logo-img {
      position: absolute;
      height: 45px;
      margin-left: 15px;
      margin-top: 5px;
    }
    p {
      position: absolute;
      color: black;
      font-size: 0.8em;
      margin-top: 45px;
      margin-left: 60px;
      font-family: Arial, Helvetica, sans-serif;
      z-index: 10;
    }
  }

  .bottom-border {
    height: 30px;
    background-image: linear-gradient(to right, white, #002a79d1);
    p {
      margin-left: 200px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
    }
  }

  ul {
    padding-top: 70px;
    // padding-right: 30px;
    padding-bottom: 10px;

    li {
      position: relative;
      padding-left: 0px;
      font-size: 16px;
      // margin-bottom: 34px;
      color: #ffffff;
      .row {
        .prefix {
          text-align: right;
          padding-right: 0;
        }
      }

      span {
        color: $template_color;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1;
        font-size: 0.9em;
      }
      p {
        color: black;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.9em;
        line-height: 1.1;
        margin-top: 0;
        padding-top: 0;
        font-weight: 400;
        // // align-content: center;
        // align-items: top;
      }
      .row {
        align-items: baseline;
        margin-top: 0px;
      }

      &:last-child {
        margin-bottom: 0;
      }
      i {
        position: absolute;
        left: 0;
        top: -11px;
        color: $template_color;
        font-size: 19px;
        background: #f6f6f6;
        width: 45px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-radius: 50%;
      }
    }
  }
}

.contact-form {
  padding-top: 40px;
  padding-bottom: 50px;
  background: $dark-grey-color;
  .section-title {
    margin-bottom: 30px;
    h4 {
      text-transform: none;
      color: white;
    }
  }

  .container {
    width: 42%;
    color: white;
  }
  @media only screen and (max-width: 600px) {
    .container {
      width: 100%;
      color: white;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;
    }
  }
}

/*
==============================
Page Title CSS
==============================
*/
.page-title {
  height: 550px;
  position: relative;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  overflow: hidden;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.6;
  }
  h1 {
    margin: {
      bottom: 35px;
      top: 65px;
    }
    color: #ffffff;
    font: {
      size: 45px;
      weight: 600;
    }
  }
  ul {
    li {
      display: inline-block;
      margin: 0 3px;
      color: #ffffff;
      text-transform: uppercase;
      font-weight: 500;
      &.active {
        color: #cccccc;
      }
      a {
        color: #ffffff;
        &:hover {
          color: $template_color;
        }
      }
    }
  }
}

/*
==============================
Blog Details Area CSS
==============================
*/
.blog-details-area {
  background: #222222;

  .title {
    margin: {
      top: 30px;
      bottom: 30px;
    }
    font-size: 27px;
  }
}
.blog-details {
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  background: $dark-grey-color;

  .article-img {
    position: relative;

    .date {
      position: absolute;
      bottom: 15px;
      width: 100px;
      left: 15px;
      height: 100px;
      text-align: center;
      font: {
        size: 25px;
        style: italic;
      }
      background: $template_color;
      color: #ffffff;
      border-radius: 5px;
      padding-top: 19px;
      line-height: 32px;
    }
  }
  .article-text {
    padding: 30px;

    ul {
      li {
        margin-right: 4px;
        display: inline-block;
      }
    }
    .category {
      a {
        background: $template_color;
        color: #ffffff;
        padding: 3px 15px;
        display: inline-block;
        border-radius: 3px;
        font-weight: 400;
      }
    }
    p {
      margin-bottom: 20px;
    }
    .blockquote {
      margin: {
        bottom: 25px;
        top: 25px;
      }
      background: #222222;
      padding: 30px;
      font-size: 17px;
      border-left: 4px solid $template_color;

      p {
        color: #dddddd;
      }
    }
    .author-share {
      margin-top: 40px;

      .social-share {
        text-align: right;
        margin-top: 28px;

        a {
          background: $template_color;
          color: #ffffff;
          width: 35px;
          display: inline-block;
          height: 35px;
          text-align: center;
          line-height: 35px;
          border-radius: 50%;
        }
      }
    }
    .article-author {
      overflow: hidden;

      .author-img {
        float: left;
        width: 27%;

        img {
          border-radius: 50%;
          max-width: 100%;
          width: auto !important;
        }
      }
      .author-title {
        float: left;
        width: 73%;
        padding-left: 25px;
        margin-top: 17px;
        h4 {
          font-size: 20px;
          margin-bottom: 6px;
          text-transform: uppercase;
        }
        span {
          color: #666666;
          font-size: 15px;
        }
      }
    }
  }
}
.post-controls-buttons {
  margin: {
    top: 50px;
    bottom: 50px;
  }
  padding: {
    left: 70px;
    right: 70px;
  }

  div {
    display: inline-block;
    a {
      box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
      padding: 10px 20px;
      border-radius: 5px;
      background: $dark-grey-color;
      color: $template_color;
      &:hover {
        background: $template_color;
        color: #ffffff;
      }
    }
  }
  .controls-right {
    float: right;
  }
}
.post-comments {
  .single-comment {
    box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
    padding: 25px;
    margin-bottom: 25px;
    position: relative;
    overflow: hidden;
    background: $dark-grey-color;

    &.left-m {
      margin-left: 30px;
    }
    .comment-img {
      float: left;
      width: 13%;
      img {
        border-radius: 50%;
      }
    }
    .comment-content {
      float: left;
      width: 87%;
      padding-left: 25px;
      h4 {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 15px;
      }
      span {
        color: #dddddd;
      }
      p {
        margin-bottom: 15px;
      }
      a {
        position: absolute;
        right: 25px;
        bottom: 25px;
        color: $template_color;
        text-transform: uppercase;
        font-size: 17px;
      }
    }
  }
}
.leave-Link-reply {
  box-shadow: 0 5px 10px rgba(0, 9, 128, 0.035), 0 7px 18px rgba(0, 9, 128, 0.05);
  padding: 25px;
  background: $dark-grey-color;
  margin-top: 50px;

  .form-control {
    background: #222222;
    color: #ffffff;
    border: none;
  }
  .btn {
    margin-top: 0px;
  }
}

.footer {
  // padding-bottom: 10px;
  position: relative;
  bottom: 0;
  width: 100%;
  height: 3.5rem;
  width: 100%;
  // height: 60px;
  background: $footer-color;
  border-top: 1px solid $dark-color;
  @media only screen and (max-width: 600px) {
    padding-bottom: 120px;
  }

  .ul-footer {
    background: $footer-color;
    // margin-left: 30%;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 600px) {
      margin-left: 10%;
      display: inline-block;
      list-style: none;
    }

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
      background-color: $footer-color;
    }

    li {
      float: left;
    }

    li a {
      display: block;
      color: white;
      text-align: center;
      padding: 14px 16px;
      text-decoration: none;
      @media only screen and (max-width: 600px) {
        width: 120px;
        display: inline-block;
      }
    }

    /* Change the link color to #111 (black) on hover */
    li a:hover {
      cursor: pointer;
      background-color: #111;
      color: $template_color;
    }
  }

  .container {
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
    background-color: $footer-color;
    margin-top: 15px;
    .bg-footer {
      // width: 100%;
      background: $footer-color !important;
    }
    .row {
      width: 100% !important;
    }
  }
  // flex-direction: column;
  // min-height: 50vh;
  // min-height: 100vh;
}
/*
==============================
Footer Area CSS
==============================
*/
.footer-area {
  // height: 50px;
  position: absolute;
  // bottom: 0;
  // // margin-top: 220px;
  // text-align: center;
  // // padding: 30px 0;
  // padding-top: 0px;
  // padding-bottom: 12px;
  // margin-top: 100%;
  // position: relative;
  // left:0;
  bottom: 0;
  // right:0;

  background: $dark-grey-color;

  h4 {
    line-height: 22px;
    margin-bottom: 30px;

    a {
      color: #ffffff;
      font-weight: 600;
      text-transform: uppercase;
      font-size: 35px;
    }
    span {
      color: $template_color;
      font: {
        size: 40px;
        weight: 700;
      }
      text-transform: lowercase;
    }
  }

  p {
    line-height: 1;
    margin-top: 12px;
    color: #dddddd;
  }
}
/*
==============================
Go Top CSS
==============================
*/
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  right: 20px;
  font-size: 25px;
  color: #ffffff;
  background-color: #000000;
  z-index: 1;
  display: none;
  width: 40px;
  text-align: center;
  height: 40px;
  line-height: 39px;
  border-radius: 50%;
  transition: 0.2s;
  &:hover {
    color: #ffffff;
    background: $template_color;
  }
}
.lfm__typing_effect {
  display: inline-block;
  padding-left: 12px;
}

.dropdown-menu {
  border: 0;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  border-radius: 0.125rem;
  @include transition(100ms, linear);
  // font-size: $font-size-base;

  &.dropdown-menu-right {
    &:before {
      left: auto;
      right: 10px;
    }
  }

  .dropdown & {
    @include transform-translate-y-dropdown(-25px);
    visibility: hidden;
    display: block;
    @include opacity(0);
    top: 100% !important;
  }

  .dropdown.show &,
  &.open {
    @include opacity(1);
    visibility: visible;
    @include transform-translate-y-dropdown(0px);
  }

  .dropdown-item,
  .bootstrap-select &.inner li a {
    //   font-size: $font-size-small;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    margin-top: 5px;
    @include transition(150ms, linear);
    outline: none;

    &:hover,
    &:focus {
      background-color: $dark-color;
      color: white;
    }

    &.disabled,
    &:disabled {
      // color: $default-color-opacity;

      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }

  &:before {
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: white;
    border-bottom: 0.4em solid;
    border-right: 0.4em solid transparent;
    border-left: 0.4em solid transparent;
  }

  .dropdown-divider {
    background-color: $dark-grey-color;
  }
}

.impressum-wrapper {
  position: relative;
  height: 130vh;
  width: 100%;
  padding-top: 100px;
  // padding-bottom: 30px;
  background: $dark-grey-color;
}

.company-wrapper {
  background: $dark-grey-color;
}

.section-impressum {
  position: relative;
  // margin-top: 119px;
  // padding-bottom: 77px;
  .description-container {
    margin-top: 5%;
    align-content: center;
  }
  .container {
    width: 65%;
    // height: 100%;
    max-width: 100%;
  }
  .description-title {
    color: $template_color;
    margin-bottom: 5px;
  }
  .description {
    max-width: 500px;
    margin-top: 0;
    margin: 0;
    margin-right: 15px;
    text-align: justify;
    white-space: pre-line;
    // display: inline-block;
  }

  .paragraph {
    white-space: pre-line;
  }
}

.section-datenschutz {
  background: $dark-grey-color;
  white-space: pre-line;
  padding-top: 120px;
  height: 100%;
  position: relative;

  ::-webkit-scrollbar {
    width: 10px;
    margin-left: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .big-datenschutz-text {
    // margin-top: 100px;
    // margin-left: 12%;
    margin-right: 10px;
    padding-right: 10px;
    display: inline-block;
    max-height: 70vh;
    // height: 625px;
    // width: 75%;
    white-space: pre-line;
    overflow-y: scroll;
    margin-bottom: 50px;
  }
}

.section-leistungen {
  padding-top: 12vh;
  padding-bottom: 90px;
  .container {
    width: 100%;
  }
  .description {
    // width: 92%;
    margin-right: 15px;
    text-align: justify;
    // display: inline-block;
  }
  .section-title {
    margin-bottom: 10px;
    p {
      max-width: 800px;
    }
    h4 {
      color: white;
    }
    padding-bottom: 0;
  }
  .subtitle {
    margin-top: 35px;
    height: unset;
  }

  .list {
    margin-top: 10px;
  }

  ul li {
    color: white;
    list-style-type: square;
    padding: 3px;
    margin-left: 6%;
    position: relative;
  }
}

.section-buero {
  $card-body-color: $dark-color;
  white-space: pre-line;

  margin-top: 60px;
  padding-bottom: 50px;
  .card-row {
    margin-top: 25px;
  }
  .subtitle {
    color: white;
    margin-right: 15px;
    text-align: left;
  }
  .description {
    // width: 92%;
    margin-right: 15px;
    text-align: justify;
    color: white;
    // display: inline-block;
  }
  .card {
    //   background-color: $dark-color;
    background-color: $card-body-color;
    border-radius: 25px !important;
    margin-left: 10px;
    margin-right: 25px;
  }
  .card-header {
    background-color: white;

    border: white;
    border-bottom: 1px;
    border-bottom-style: solid;
    color: $dark-color;
    // color: black;
    padding-left: 20px;
    text-align: left;
    align-content: center;
    font-size: 1.3em;
    padding-top: 4px;
    padding-bottom: 4px;
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
  }

  .card-title {
    font-weight: 600;
    color: white;
    margin-top: 8px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
  }

  .card-body {
    border-radius: 25px;
    margin-top: -10px;
    background-color: $dark-color;
    background-color: $card-body-color;
  }

  .card-text {
    color: white;
  }
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.button:hover {
  cursor: pointer;
}

.navbar-animation-enter {
  opacity: 0.01;
}

.navbar-animation-enter.navbar-animation-enter-active {
  opacity: 1;
  // transition: opacity 500ms ease-in;
  animation: 300ms ease-in 0s normal none 1 running fadeInDown;
}

.navbar-animation-leave {
  opacity: 1;
}
  
.navbar-animation-leave.navbar-animation-leave-active {
  opacity: 0.01;
  // transition: opacity 300ms ease-in;
  animation: 300ms ease-in 0s normal none 1 running fadeOutUp;
}

.section-geschaeftsfuehrung {
  background-color: $dark-grey-color;
  padding-top: 100px;
  padding-bottom: 50px;
  .row {
    @media only screen and (max-width: 600px) {
      display: block;
    }
  }
  .werdegang-wrapper {
    border-left: 3px solid $template_color;
  }
  .werdegang {
    white-space: pre-wrap;
    color: white;
    margin-top: 15px;
    // &::after {
    //   content: "";
    //   position: absolute;
    //   width: 3px;
    //   height: 100%;
    //   left: 10px;
    //   top: 0;
    //   background: $template_color;
    // }
    
    margin-left: 10px
  }

  .name {
    margin-bottom: 15px;
    margin-top: 25px;
  }

  .section-title {
    margin-bottom: 0;
  }
}

.card-container {
  // margin-right: 10px;
  margin-left: 10px;
  .card-deck {
    margin-bottom: 40px;
  }
  .card {
    background-color: transparent;
    overflow: hidden;
    box-shadow: none;
    @media only screen and (max-width: 600px) {
      margin: 15px 5px 25px 0px;
    }
  }
  .card-title {
    font-weight: 600;
  }

  .card-body {
    background-color: $dark-color;
  }
  .card-text {
    min-height: 50px;
  }
  .zoom-wrapper {
    height: 300x;

    overflow: hidden;
  }

  .card-img:hover {
    transition: transform 0.5s ease;
    transform: scale(1.25);
  }

  .card-img {
    cursor: pointer;
    object-fit: cover;
    height: 240px;
    width: 100%;
    // min-height: 15vw;
    transition: transform 0.25s ease;
  }
}

.page-not-found-section {
  position: relative;
  height: 95vh;
  // margin-bottom: 100%;

  h1 {
    margin-top: 100px;
    margin-bottom: 30px;
    color: black;
  }
}

.karriere-wrapper {
  position: relative;
  white-space: pre-wrap;
  $selected-color: #7c96b0;
  height: 105vh;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 30px;
  background: $dark-grey-color;
  color: white;
  .block {
    padding-top: 15px;
    padding-bottom: 15px;
    background: $dark-color;
    border-radius: 15px;
  }
  .stellenanzeige {
    padding-bottom: 30px;
  }

  ul {
    padding-top: 15px;
    list-style-type: circle;

    li {
      margin-left: 25px;
      list-style-type: circle;
    }
    li:hover {
      cursor: pointer;
      color: $selected-color;
    }
    .selected {
      color: $selected-color;
    }
    .selected:hover {
      cursor: unset;
    }
  }
}

